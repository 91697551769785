<template>
  <div class="modal-mask" v-if="$store.state.modalImprimir" transition="modal" id="suporteAtModal" style="z-index: 9999;">
    <div class="modalScrollMobile">
      <div class="modal-wrapper">
        <div class="modal-fundo"></div>
        <div class="modal-container padding-0 modal-800">
          <div class="modal-header">
            <h3 class="modal-title">{{$t('imprimir')}}<button @click="SET_IMPRIMIR('')" type="button" class="btn btn-default btn-pure btn-lg modal-fechar"><i class="glyphicon glyphicon-remove"></i></button></h3>
          </div>
          <div class="modal-body margin-0 padding-top-0 padding-bottom-15 text-center">
            <a target="_blank" @click="SET_IMPRIMIR('')" v-if="a4 || $store.state.Demo || $store.state.dev" :href="($store.state.Demo || $store.state.dev ? 'https://dev.bill.pt' : 'https://app.bill.pt') + '/documentos/download/' + form1.id + '/' + form1.token_download" class="btn btn-primary btn-block-mobile margin-horizontal-10 margin-bottom-20" >{{l('a4')}}</a>
            <a target="_blank" @click="SET_IMPRIMIR('')" v-if="guia" :href="($store.state.Demo || $store.state.dev ? 'https://dev.bill.pt' : 'https://app.bill.pt') + '/documentos/download/' + form1.id + '/' + form1.token_download + '?noprice='" class="btn btn-primary btn-block-mobile margin-horizontal-10 margin-bottom-20" >{{l('a4_guias_sem_precos')}}</a>
            <a target="_blank" @click="SET_IMPRIMIR('')" v-if="a4 || $store.state.Demo || $store.state.dev" :href="($store.state.Demo || $store.state.dev ? 'https://dev.bill.pt' : 'https://app.bill.pt') + '/documentos/download/' + form1.id + '/' + form1.token_download + '?sem_logo=1'" class="btn btn-primary btn-block-mobile margin-horizontal-10 margin-bottom-20" >{{l('a4_sem_logo')}}</a>
            <a target="_blank" @click="SET_IMPRIMIR('')" v-if="copia || $store.state.Demo || $store.state.dev" :href="($store.state.Demo || $store.state.dev ? 'https://dev.bill.pt' : 'https://app.bill.pt') + '/documentos/download/' + form1.id + '/' + form1.token_download + '?copia_do_original=1'" class="btn btn-primary btn-block-mobile margin-horizontal-10 margin-bottom-20" >{{l('copia')}}</a>
            <a target="_blank" @click="SET_IMPRIMIR('')" v-if="triplicado || $store.state.Demo || $store.state.dev" :href="($store.state.Demo || $store.state.dev ? 'https://dev.bill.pt' : 'https://app.bill.pt') + '/documentos/download/' + form1.id + '/' + form1.token_download + '?triplicado=1'" class="btn btn-primary btn-block-mobile margin-horizontal-10 margin-bottom-20" >{{l('triplicado')}}</a>

            <div class="clearfix"></div>
            <a target="_blank" @click="SET_IMPRIMIR('')" v-if="a5 || $store.state.Demo || $store.state.dev" :href="($store.state.Demo || $store.state.dev ? 'https://dev.bill.pt' : 'https://app.bill.pt') + '/documentos/download/' + form1.id + '/' + form1.token_download + '?tamanho=A5'" class="btn btn-info btn-block-mobile margin-horizontal-10 margin-bottom-20" >{{l('a5')}}</a>
            <button v-if="talao" @click="imprimir_talao()" class="btn btn-warning btn-block-mobile margin-horizontal-10 margin-bottom-20" >{{l('talao')}}</button>

            <button v-if="talaoB" @click="imprimir_talao(1)" class="btn btn-warning btn-block-mobile margin-horizontal-10 margin-bottom-20" >{{l('talao_bluetooth')}}</button>
            <div class="clearfix margin-bottom-15"></div>
            <router-link v-if="PERMISSOES.configuracoes_empresa && !$store.state.modulo" to="/configuracoes/impressao">{{l('configuracao_impressoes')}}</router-link>
            <div class="clearfix"></div>
            <button class="btn btn-default btn-pure pull-right" type="button" @click="SET_IMPRIMIR('')">{{$t('sair')}}</button>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'modalImprimir',
  data () {
    return {
      form1: '',
      a4: false,
      a5: false,
      talao: false,
      talaoB: false,
      triplicado: false,
      copia: false,
      guia: false,

      message: '',
      loading: false,
      showError: false,
      errorMessage: '',
      printCharacteristic: null,
      isConnected: false, // Estado de conexão
      device: null, // Dispositivo Bluetooth
      canvas: null,
      context: null,
      imageData: null,
      index: 0,
      data: null
    }
  },
  watch: {
    '$route' () {
      this.SET_IMPRIMIR('')
    },
    '$store.state.modalImprimir' (val) {
      this.form1 = val
      if (val) {
        window.scrollTo(0, 0)
        let loja = this.$store.state.lojas.find(a => a.id === val.loja_id)
        if (!loja) {
          loja = {}
        }
        if (loja.a4 || (!loja.a4 && !loja.a5 && !loja.termico)) {
          this.a4 = true
        }
        if (loja.a5) {
          this.a5 = true
        }
        let tp = this.$store.state['tipos-documento'].find(a => a.id * 1 === val.tipo_documento_id * 1).tipificacao
        if (tp === 'GT' || tp === 'GR') {
          this.guia = true
        }
        if ((loja.termico || (!loja.a4 && !loja.a5 && !loja.termico && !loja.termicoB)) && tp !== 'QS') {
          if (tp !== 'SSTK' && tp !== 'SINIV' && tp !== 'SINIC' && tp !== 'RFC' && tp !== 'FO') {
            this.talao = true
          }
        }
        if ((loja.termicoB || (!loja.a4 && !loja.a5 && !loja.termico && !loja.termicoB)) && tp !== 'QS') {
          if (tp !== 'SSTK' && tp !== 'SINIV' && tp !== 'SINIC' && tp !== 'RFC' && tp !== 'FO') {
            this.talaoB = true
          }
        }
        if (val.impresso) {
          this.copia = true
        }
        if (this.$store.getters.TRIPLICADO && tp !== 'SSTK') {
          this.triplicado = true
        }
      }
    }
  },
  computed: {
    PERMISSOES: function () {
      return this.$store.state.utilizador.permissoes
    }
  },
  mounted () {
    this.a4 = false
    this.a5 = false
    this.talao = false
    this.talaoB = false
    this.triplicado = false
    this.copia = false
    this.guia = false
  },
  methods: {
    ...mapMutations([
      'SET_IMPRIMIR',
      'SET_LOAD'
    ]),
    l (a) {
      return this.$t('impressao.' + a)
    },
    imprimir_talao (op) {
      /*
      let self = this
      self.SET_LOAD(true)
      let myframe = document.createElement('IFRAME')
      myframe.src = (this.$store.state.Demo || this.$store.state.dev ? 'https://dev.bill.pt' : 'https://app.bill.pt') + '/documentos/download/' + this.form1.id + '/' + this.form1.token_download + '?tamanho=talao'
      myframe.style.position = 'absolute'
      myframe.style.top = '-10000px'
      document.body.appendChild(myframe)
      myframe.onload = function () {
        myframe.contentWindow.focus()
        myframe.contentWindow.print()
        myframe.parentNode.removeChild(myframe)
        self.SET_LOAD()
      }
      this.SET_IMPRIMIR('')
      }
      */
      this.SET_LOAD(true)
      this.axios.get((this.$store.state.Demo || this.$store.state.dev ? 'https://dev.bill.pt' : 'https://app.bill.pt') + '/documentos/download/' + this.form1.id + '/' + this.form1.token_download + '?tamanho=talao').then((dta) => {
        if (op) {
          this.SET_LOAD()
          this.printHtmlContent(dta.data)
          return
        }
        let _id = 'id_' + new Date().getTime()

        let myframe = document.createElement('IFRAME')
        myframe.id = _id
        myframe.style = 'visibility:hidden;position:absolute;top:-10000px;width:1px;height:1px;'
        document.body.appendChild(myframe)
        myframe.contentWindow.document.open()
        myframe.contentWindow.document.write(dta.data)
        myframe.contentWindow.document.close()
        this.loadIframe(document.getElementById(_id))
      }, () => {
        this.SET_LOAD()
      })
    },
    loadIframe (doc) {
      let iframeDoc = doc.contentDocument || doc.contentWindow.document
      if ( iframeDoc.readyState  === 'complete' ) {
        window.setTimeout((a, doc) => {
          doc.contentWindow.focus()
          doc.contentWindow.print()
          a.SET_LOAD()
          a.SET_IMPRIMIR('')
        }, 10, this, doc)
        return
      }
      window.setTimeout((doc, a) => { a.loadIframe(doc) }, 10, doc, this)
    },

    async printHtmlContent(htmlContent) {
      try {
      // Conectar à impressora Bluetooth
        const device = await navigator.bluetooth.requestDevice({
          filters: [{ services: ["000018f0-0000-1000-8000-00805f9b34fb"] }],
        });
        const server = await device.gatt.connect();
        const service = await server.getPrimaryService(
          "000018f0-0000-1000-8000-00805f9b34fb"
          );
        const printCharacteristic = await service.getCharacteristic(
          "00002af1-0000-1000-8000-00805f9b34fb"
          );

      // Gerar dados de impressão
        const receiptContent = this.parseHtmlToReceipt(htmlContent);
        const encoder = new TextEncoder("utf-8");
        const text = encoder.encode(receiptContent + "\u000A\u000D");

      // Dividir o conteúdo em partes menores (512 bytes ou menos)
        const chunkSize = 512;
        for (let i = 0; i < text.length; i += chunkSize) {
          const chunk = text.slice(i, i + chunkSize);
          await printCharacteristic.writeValue(chunk);
        }

        // console.log("HTML content printed successfully");
      } catch (error) {
        this.$swal(this.$t('impressao_titulo_erro'), this.$t('impressao_desc_conectar') + ' ' + error, 'error')
        // console.error("Erro ao conectar à impressora ou enviar os dados:", error);
      }
    },

    parseHtmlToReceipt(htmlContent) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, "text/html");

      let receiptText = "";

    // Helper para decodificar entidades HTML e garantir UTF-8
      const decodeHtmlEntities = (text) => {
        if (!text) return "";
        const textarea = document.createElement("textarea");
        textarea.innerHTML = text;
        return textarea.value;
      };

    // Empresa e endereço
      const companyTable = doc.querySelector("table.text-center");
      if (companyTable) {
        const companyDetails = companyTable.querySelectorAll("tr td");
        companyDetails.forEach((td) => {
          receiptText += decodeHtmlEntities(td.textContent.trim()) + "\n";
        });
        receiptText += "\n";
      }

    // Recibo e número
      const receiptInfo = doc.querySelector("table:nth-of-type(2) tr");
      if (receiptInfo) {
        const left = receiptInfo.querySelector("td.text-left");
        const right = receiptInfo.querySelector("td.text-right");
        if (left && right) {
          receiptText += `${decodeHtmlEntities(left.textContent.trim())}  ${decodeHtmlEntities(right.textContent.trim())}\n\n`;
        }
      }

    // Segunda via e data
      const secondaryInfo = doc.querySelector("table:nth-of-type(3) tr");
      if (secondaryInfo) {
        const left = secondaryInfo.querySelector("td.text-left");
        const right = secondaryInfo.querySelector("td.text-right");
        if (left && right) {
          receiptText += `${decodeHtmlEntities(left.textContent.trim())}  ${decodeHtmlEntities(right.textContent.trim())}\n\n`;
        }
      }

    // Cliente e método de pagamento
      const clientInfo = doc.querySelectorAll("table:nth-of-type(4) tr td");
      clientInfo.forEach((td) => {
        receiptText += decodeHtmlEntities(td.textContent.trim()) + "\n";
      });
      receiptText += "\n";

    // Funcionário
      const employeeInfo = doc.querySelector("table:nth-of-type(5) td");
      if (employeeInfo) {
        receiptText += decodeHtmlEntities(employeeInfo.textContent.trim()) + "\n\n";
      }

    // Documento, desconto, total
      const mainTable = doc.querySelector("main table");
      if (mainTable) {
        const rows = mainTable.querySelectorAll("tr");
        rows.forEach((row) => {
          const cells = row.querySelectorAll("td");
          const line = Array.from(cells)
          .map((cell) => decodeHtmlEntities(cell.textContent.trim()))
          .join("     ");
          receiptText += line + "\n";
        });
        receiptText += "\n";
      }

    // Observações finais
      const notes = doc.querySelectorAll("div[style*='text-align:center']");
      notes.forEach((note) => {
        receiptText += decodeHtmlEntities(note.textContent.trim()) + "\n";
      });

      return receiptText;
    }
  }
}
</script>
